import React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import IdeetippList from "../components/IdeetippList"
import setupTags from "../utils/setupTags"
import slugify from "slugify"
import deChar from "../utils/deChar"
import SEO from "../components/SEO"

const Kategorien = ({
  data: {
    allContentfulIdeetipp: { nodes: ideetipp },
    taglist: { nodes: ideetipps },
  },
}) => {
  const newTags = setupTags(ideetipps)
  return (
    <Layout>
      <SEO title="Kategorien " />
      <main className="page">
        <section className="main">
          <h1>Kategorien</h1>
        </section>
        <section className="tags-page">
          {newTags.map((tag, index) => {
            const [text, value] = tag
            const deDE = deChar(text)
            const slug = slugify(deDE, { lower: true })
            return (
              <Link to={`/tags/${slug}`} key={index} className="tag">
                <h5>{text}</h5>
                <p>{value} Seiten</p>
              </Link>
            )
          })}
        </section>
        <section className="featured-recipes">
          <h5>Schau dir die tollen Tipps und Ideen an!</h5>
          <IdeetippList ideetipp={ideetipp} />
        </section>
      </main>
    </Layout>
  )
}

export const data = graphql`
  {
    allContentfulIdeetipp(
      sort: { fields: createdAt, order: DESC }
      filter: { featured: { eq: true } }
      limit: 3
    ) {
      nodes {
        id
        title
        createdAt
        subtitle
        durationTime
        prepTime
        image {
          id
          gatsbyImageData(placeholder: BLURRED)
        }
        content {
          tags
        }
      }
    }
    taglist: allContentfulIdeetipp {
      nodes {
        content {
          tags
        }
      }
    }
  }
`

export default Kategorien
